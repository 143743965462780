import { useMediaQuery } from "react-responsive";
import { ReactComponent as TelegramDesktop } from "../../assets/social-icons/Telegram-desktop.svg";
import { ReactComponent as TwitterDesktop } from "../../assets/social-icons/Twitter-desktop.svg";
import { ReactComponent as DiscordDesktop } from "../../assets/social-icons/discord-desktop.svg";

import { CLOUD_FRONT_URL } from "../../config/constants";
import { socialLink, TRADE_ON } from "../../config/external-links";
import { BackgroundVideoPlayer } from "../BackgroundVideoPlayer";
import HoveredStateIcon from "../HoveredStateIcon";
import { MotionInView } from "../MotionInView";
import SocialIcons from "../SocialIcons";
import { varFadeInUp } from "../variants";

const footerLinks = {
  About: [
    {
      label: "Stats",
      href: "#",
    },
    {
      label: "Docs",
      href: "#",
    },
    {
      label: "Careers",
      href: "#",
    },
    {
      label: "Media Kit",
      href: "#",
    },
    {
      label: "Terms of Use",
      href: "#",
    },
    {
      label: "Privacy Policy",
      href: "#",
    },
  ],

  Products: [
    {
      label: "Perpetuals",
      href: "#",
    },
    {
      label: "Vaults",
      href: "#",
    },
    {
      label: "Developers",
      href: "#",
    },
    {
      label: "Pro",
      href: "#",
    },
  ],

  Support: [
    {
      label: "Discord",
      href: "#",
    },
    {
      label: "Status",
      href: "#",
    },
  ],
};

const Slide7 = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <section className="relative pt-[120px] pb-[89px] xl:pt-0 xl:pb-0 min-h-screen xl:h-screen overflow-hidden w-full">
      {!isMobile && (
        <BackgroundVideoPlayer
          webMVideo={`${CLOUD_FRONT_URL}/videos/slide10.webm`}
          mp4Video={`${CLOUD_FRONT_URL}/videos/slide10.mp4`}
          videoOptions={{
            className:
              "w-full object-cover opacity-30 mix-blend-difference absolute h-full",
          }}
        />
      )}

      <div className="relative h-full w-full flex flex-col items-center justify-end">
        <div
          className={`flex flex-col gap-12 ${
            isMobile && "items-center w-full px-6 flex flex-col"
          }`}
        >
          <MotionInView variants={varFadeInUp}>
            <div
              className={`flex flex-col gap-8 w-[320px] items-center justify-center`}
            >
              <img alt="logo" src="/icons/logo.svg" width={320} height={120} />
            </div>
          </MotionInView>

          <MotionInView
            variants={varFadeInUp}
            className="w-full flex justify-center"
          >
            <div className="mt-[25px] flex justify-between items-center w-[320px]">
              <a
                target="_blank"
                rel="noreferrer"
                href={socialLink.DISCORD_URL}
                className="hover:[&_svg_path]:fill-[#5865f2] duration-200"
              >
                <DiscordDesktop />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={socialLink.TWITTER_URL}
                className="hover:[&_svg_path]:fill-white duration-200"
              >
                <TwitterDesktop />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={socialLink.TELEGRAM_URL}
                className="hover:[&_svg_path]:fill-[#08c] duration-200"
              >
                <TelegramDesktop />
              </a>
              {/* <a
                target="_blank"
                rel="noreferrer"
                href={socialLink.GITHUB_URL}
                className="hover:[&_svg_path]:fill-[#b8b9bf] duration-200"
              >
                <GithubDesktop />
              </a> */}
              <a target="_blank" rel="noreferrer" href={socialLink.MIRROR_URL}>
                <HoveredStateIcon
                  icon={
                    <img
                      alt=""
                      className="w-[24px] h-[24px]"
                      src="/icons/mirror.svg"
                    />
                  }
                  hoverIcon={
                    <img
                      alt=""
                      className="w-[24px] h-[24px]"
                      src="/icons/mirror-colored.svg"
                    />
                  }
                />
              </a>
            </div>
          </MotionInView>
          {/* <SignUp
            containerClasses="flex gap-2 items-start justify-between"
            mobileContainerClasses="flex-col w-full"
          /> */}
        </div>

        <MotionInView
          variants={varFadeInUp}
          className="mt-8 mb-6 w-full flex items-center justify-center gap-4"
        >
          <div className="text-sm font-medium text-greyscale-text-secondary">
            Powered By
          </div>
          <div className="flex gap-2 items-center text-greyscale-text-primary text-base font-semibold">
            <img width={24} height={24} src="/icons/pyth-footer.svg" alt="" />
            Pyth Network
          </div>
        </MotionInView>

        {/* <div className="m-6 pt-6 xl:hidden flex justify-between w-full px-6 items-center">
          <FooterHeading label="Community" />
          <SocialIcons className="!flex-row !gap-[10px]" />
        </div> */}

        <div className="px-6 sm:px-0 w-full border-0 pb-[48px] min-h-[257px] xl:mt-[90px] pt-6 xl:border-t border-border-primary flex items-start text-left justify-between">
          <div className="text-left px-6 3xl:px-16 hidden xl:flex flex-col gap-4">
            <FooterHeading label="Community" />
            <SocialIcons className="!flex-row !gap-[10px]" />
          </div>

          <div className="flex flex-col gap-4">
            <FooterHeading label="About" />
            <FooterLinks links={footerLinks["About"]} />
          </div>

          <div className="flex flex-col gap-4">
            <FooterHeading label="Products" />
            <FooterLinks links={footerLinks["Products"]} />
          </div>

          <div className="flex flex-col gap-4">
            <FooterHeading label="Support" />
            <FooterLinks links={footerLinks["Support"]} />
          </div>

          <div className="xl:flex flex-col gap-4 px-6 3xl:px-16 hidden">
            <FooterHeading label="RFX" />
            <a
              href={""}
              className="bg-main uppercase rounded text-greyscale-text-primary text-base font-semibold px-6 h-12 flex flex-col items-center justify-center"
            >
              Start Trading
              <span className="text-[10px]">(Coming Soon)</span>
            </a>
          </div>
        </div>

        <div className="xl:hidden flex-col gap-4 px-16 flex">
          <a
            href={""}
            className="bg-main uppercase rounded text-greyscale-text-primary text-base font-semibold px-6 h-12 flex flex-col items-center justify-center"
          >
            Start Trading
            <span className="text-[10px]">(Coming Soon)</span>
          </a>
        </div>
      </div>
    </section>
  );
};

const FooterHeading = ({ label }) => {
  return (
    <div className="text-base font-semibold text-greyscale-text-primary leading-6">
      {label}
    </div>
  );
};

const FooterLinks = ({ links }) => {
  return (
    <div className="flex flex-col gap-3">
      {links.map((link) => (
        <a
          key={link.label}
          href={link.href}
          className="text-greyscale-text-secondary text-sm font-medium hover:text-greyscale-text-primary"
        >
          {link.label}
        </a>
      ))}
    </div>
  );
};

export default Slide7;
